import { ICharge, schemes, zSchema } from "@zeal/common";

export const getChargeRequestSchema = zSchema.object({
  odo_user_id: schemes.mongoOrUuid(),
  charge_id: schemes.uuid().optional(),
  status: zSchema.enum(["pending", "settled"]).optional(),
});

export type TGetChargeRequestSchema = zSchema.infer<
  typeof getChargeRequestSchema
>;

export type TGetChargeResponseSchema = ICharge[];
