import { AxiosResponse } from "axios";
import { IUpdateDeadline } from "@zeal/common/dist/bolarus/deadline_types";
import { buildResponseBase } from "@zeal/common";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";

export const updateDeadline =
  (axios: IAxiosInstance) => async (obj: IUpdateDeadline) => {
    const url = "/private/api/compass/updateDeadline";

    const res = await axios.noRetry.request<
      any,
      AxiosResponse<any>,
      IUpdateDeadline
    >({
      method: "PUT",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
