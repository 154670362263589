import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { authPushToCardUser } from "./routes/authPushToCardUser/authPushToCardUser";
import { companyAddAch } from "./routes/companyAddAch/companyAddAch";
import { companyKybOnboard } from "./routes/companyKybOnboard/companyKybOnboard";
import { companyOnboard } from "./routes/companyOnboard/companyOnboard";
import { createBankAccount } from "./routes/createBankAccount/createBankAccount";
import { createCustodyNode } from "./routes/createCustodyNode/createCustodyNode";
import { createKybEvaluation } from "./routes/createKybEvaluation/createKybEvaluation";
import { createPayCard } from "./routes/createPayCard/createPayCard";
import { createPushToCardBusiness } from "./routes/createPushToCardBusiness/createPushToCardBusiness";
import { createPushToCardBusinessAccount } from "./routes/createPushToCardBusinessAccount/createPushToCardBusinessAccount";
import { createPushToCardWorker } from "./routes/createPushToCardWorker/createPushToCardWorker";
import { createTransaction } from "./routes/createTransaction/createTransaction";
import { createWallet } from "./routes/createWallet/createWallet";
import { customerAccountAddAch } from "./routes/customerAccountAddAch/customerAccountAddAch";
import { customerAccountOnboard } from "./routes/customerAccountOnboard/customerAccountOnboard";
import { getAstraAccountBalance } from "./routes/getAstraAccountBalance/getAstraAccountBalance";
import { getCardInfo } from "./routes/getCardInfo/getCardInfo";
import { getCompanies } from "./routes/getCompany/getCompanies";
import { getCompany } from "./routes/getCompany/getCompany";
import { getCompanyAccounts } from "./routes/getCompanyAccounts/getCompanyAccounts";
import { getCompanyCardProducts } from "./routes/getCompanyCardProducts/getCompanyCardProducts";
import { getCompanyMicroDeposits } from "./routes/getCompanyMicroDeposits/getCompanyMicroDeposits";
import { getCompanyStatus } from "./routes/getCompanyStatus/getCompanyStatus";
import { getCustomerAccountMicroDeposits } from "./routes/getCustomerAccountMicroDeposits/getCustomerAccountMicroDeposits";
import { getEmployeeWatchlistStatus } from "./routes/getEmployeeWatchlistStatus/getEmployeeWatchlistStatus";
import { getLedgerForUser } from "./routes/getLedgerForUser/getLedgerForUser";
import { getPayCards } from "./routes/getPayCards/getPayCards";
import { getPaycardStatement } from "./routes/getPaycardStatement/getPaycardStatement";
import { getPaycardStatements } from "./routes/getPaycardStatements/getPaycardStatements";
import { getPayCardToken } from "./routes/getPayCardToken/getPayCardToken";
import { getPayCardTransaction } from "./routes/getPaycardTransaction/getPaycardTransaction";
import { getPayCardTransactions } from "./routes/getPayCardTransactions/getPayCardTransactions";
import { getPaymentDestinations } from "./routes/getPaymentDestinations/getPaymentDestinations";
import { getPendingTopUps } from "./routes/getPendingTopUps/getPendingTopUps";
import { getStateAgencyInfo } from "./routes/getStateAgencyInfo/getStateAgencyInfo";
import { getStateAgencyTransactionsBulk } from "./routes/getStateAgencyTransactionsBulk/getStateAgencyTransactionsBulk";
import { getTransactionEvents } from "./routes/getTransactionEvents/getTransactionEvents";
import { getTransactionRecord } from "./routes/getTransactionRecord/getTransactionRecord";
import { getTransactions } from "./routes/getTransactions/getTransactions";
import { getTransactionStatus } from "./routes/getTransactionStatus/getTransactionStatus";
import { getUserData } from "./routes/getUserData/getUserData";
import { getWallets } from "./routes/getWallets/getWallets";
import { getWalletTransactions } from "./routes/getWalletTransactions/getWalletTransactions";
import { listAccountBalances } from "./routes/listAccountBalances/listAccountBalances";
import { migrateOneCompany } from "./routes/migrateCompanies/migrateOneCompany";
import { getUnmigratedWorkers } from "./routes/migrateWorkers/getUnmigratedWorkers";
import { migrateOneWorker } from "./routes/migrateWorkers/migrateOneWorker";
import { onboardEmployee } from "./routes/onboardEmployee/onboardEmployee";
import { reconcileBankAccounts } from "./routes/reconcileBankAccounts/reconcileBankAccounts";
import { resetCompanyMicroDeposits } from "./routes/resetCompanyMicroDeposits/resetCompanyMicroDeposits";
import { runAllKYC } from "./routes/runAllKYC/runAllKYC";
import { runCompanyKYB } from "./routes/runCompanyKYB/runCompanyKYB";
import { runKYC } from "./routes/runKYC/runKYC";
import { runSSNVerification } from "./routes/runSSNVerification/runSSNVerification";
import { triggerCompanyMicroDeposits } from "./routes/triggerCompanyMicroDeposits/triggerCompanyMicroDeposits";
import { triggerCustomerAccountMicroDeposits } from "./routes/triggerCustomerAccountMicroDeposits/triggerCustomerAccountMicroDeposits";
import { updateBankAccount } from "./routes/updateBankAccount/updateBankAccount";
import { updateCompanyNode } from "./routes/updateCompanyNode/updateCompanyNode";
import { updateKYCInfo } from "./routes/updateKYCInfo/updateKYCInfo";
import { updatePaycard } from "./routes/updatePaycard/updatePaycard";
import { updateTransactionStatuses } from "./routes/updateTransactionStatuses/updateTransactionStatuses";
import { verifyCompanyMicroDeposits } from "./routes/verifyCompanyMicroDeposits/verifyCompanyMicroDeposits";
import { verifyCustomerAccountMicroDeposits } from "./routes/verifyCustomerAccountMicroDeposits/verifyCustomerAccountMicroDeposits";
import { verifyRoutingNumber } from "./routes/verifyRoutingNumber/verifyRoutingNumber";
import {
  assertValidOdoSchema,
  OdoSchemaAssertionWithParams,
} from "./validation-types/assertValidOdoSchema";
import { updatePushToCardUserCard } from "./routes/updatePushToCardUserCard/updatePushToCardUserCard";
import { createChargePolicy } from "./routes/createChargePolicy/createChargePolicy";
import { getChargePolicy } from "./routes/getChargePolicy/getChargePolicy";
import { updateChargePolicy } from "./routes/updateChargePolicy/updateChargePolicy";
import { calculateCharges } from "./routes/calculateCharges/calculateCharges";
import { getCharge } from "./routes/getCharges/getCharge";

export class OdoClient extends AbstractApiClient {
  public static AssertValidSchema: OdoSchemaAssertionWithParams =
    assertValidOdoSchema;
  public readonly AuthPushToCardUser = authPushToCardUser(this.Instance);
  public readonly CalculateCharges = calculateCharges(this.Instance);
  public readonly CompanyAddAch = companyAddAch(this.Instance);
  public readonly CompanyOnboard = companyOnboard(this.Instance);
  public readonly CompanyKybOnboard = companyKybOnboard(this.Instance);
  public readonly CreateBankAccount = createBankAccount(this.Instance);
  public readonly CreateCustodyNode = createCustodyNode(this.Instance);
  public readonly CreateKybEvaluation = createKybEvaluation(this.Instance);
  public readonly CreatePayCard = createPayCard(this.Instance);
  public readonly CreateChargePolicy = createChargePolicy(this.Instance);
  public readonly CreatePushToCardBusiness = createPushToCardBusiness(
    this.Instance
  );
  public readonly CreatePushToCardBusinessAccount =
    createPushToCardBusinessAccount(this.Instance);
  public readonly CreatePushToCardWorker = createPushToCardWorker(
    this.Instance
  );
  public readonly CreateTransaction = createTransaction(this.Instance);
  public readonly CreateWallet = createWallet(this.Instance);
  public readonly CustomerAccountAddAch = customerAccountAddAch(this.Instance);
  public readonly CustomerAccountOnboard = customerAccountOnboard(
    this.Instance
  );
  public readonly GetAstraAccountBalance = getAstraAccountBalance(
    this.Instance
  );
  public readonly GetCardInfo = getCardInfo(this.Instance);
  public readonly GetChargePolicy = getChargePolicy(this.Instance);
  public readonly GetCharge = getCharge(this.Instance);
  public readonly GetCompanies = getCompanies(this.Instance);
  public readonly GetCompany = getCompany(this.Instance);
  public readonly GetCompanyAccounts = getCompanyAccounts(this.Instance);
  public readonly GetCompanyCardProducts = getCompanyCardProducts(
    this.Instance
  );
  public readonly GetCompanyMicroDeposits = getCompanyMicroDeposits(
    this.Instance
  );
  public readonly GetCompanyStatus = getCompanyStatus(this.Instance);
  public readonly GetCustomerAccountMicroDeposits =
    getCustomerAccountMicroDeposits(this.Instance);
  public readonly GetEmployeeWatchlistStatus = getEmployeeWatchlistStatus(
    this.Instance
  );
  public readonly GetLedgerForUser = getLedgerForUser(this.Instance);
  public readonly GetPayCards = getPayCards(this.Instance);
  public readonly GetPaycardStatement = getPaycardStatement(this.Instance);
  public readonly GetPaycardStatements = getPaycardStatements(this.Instance);
  public readonly GetPayCardToken = getPayCardToken(this.Instance);
  public readonly GetPayCardTransaction = getPayCardTransaction(this.Instance);
  public readonly GetPayCardTransactions = getPayCardTransactions(
    this.Instance
  );
  public readonly GetPaymentDestinations = getPaymentDestinations(
    this.Instance
  );
  public readonly GetPendingTopUps = getPendingTopUps(this.Instance);
  public readonly GetStateAgencyInfo = getStateAgencyInfo(this.Instance);
  public readonly GetStateAgencyTransactionsBulk =
    getStateAgencyTransactionsBulk(this.Instance);
  public readonly GetTransactionEvents = getTransactionEvents(this.Instance);
  public readonly GetTransactionRecord = getTransactionRecord(this.Instance);
  public readonly GetTransactions = getTransactions(this.Instance);
  public readonly GetTransactionStatus = getTransactionStatus(this.Instance);
  public readonly GetUnmigratedWorkers = getUnmigratedWorkers(this.Instance);
  public readonly GetUserData = getUserData(this.Instance);
  public readonly GetWallets = getWallets(this.Instance);
  public readonly GetWalletTransactions = getWalletTransactions(this.Instance);
  public readonly ListAccountBalances = listAccountBalances(this.Instance);
  public readonly MigrateOneCompany = migrateOneCompany(this.Instance);
  public readonly MigrateOneWorker = migrateOneWorker(this.Instance);
  public readonly OnboardEmployee = onboardEmployee(this.Instance);
  public readonly ReconcileBankAccounts = reconcileBankAccounts(this.Instance);
  public readonly ResetCompanyMicroDeposits = resetCompanyMicroDeposits(
    this.Instance
  );
  public readonly RunAllKYC = runAllKYC(this.Instance);
  public readonly RunCompanyKYB = runCompanyKYB(this.Instance);
  public readonly RunKYC = runKYC(this.Instance);
  public readonly RunSSNVerification = runSSNVerification(this.Instance);
  public readonly TriggerCompanyMicroDeposits = triggerCompanyMicroDeposits(
    this.Instance
  );
  public readonly TriggerCustomerAccountMicroDeposits =
    triggerCustomerAccountMicroDeposits(this.Instance);
  public readonly UpdateBankAccount = updateBankAccount(this.Instance);
  public readonly UpdateCompanyNode = updateCompanyNode(this.Instance);
  public readonly UpdateKYCInfo = updateKYCInfo(this.Instance);
  public readonly UpdatePaycard = updatePaycard(this.Instance);
  public readonly UpdateChargePolicy = updateChargePolicy(this.Instance);
  public readonly UpdateTransactionStatuses = updateTransactionStatuses(
    this.Instance
  );
  public readonly VerifyCompanyMicroDeposits = verifyCompanyMicroDeposits(
    this.Instance
  );
  public readonly VerifyCustomerAccountMicroDeposits =
    verifyCustomerAccountMicroDeposits(this.Instance);

  public readonly VerifyRoutingNumber = verifyRoutingNumber(this.Instance);
  public readonly UpdatePushToCardUserCard = updatePushToCardUserCard(
    this.Instance
  );
}
