import { ICharge, zSchema } from "@zeal/common";

export const calculateChargesRequestSchema = zSchema.object({
  odo_user_id: zSchema.string(),
  transfer_amount: zSchema.number(),
});

export type TCalculateChargesRequestSchema = zSchema.infer<
  typeof calculateChargesRequestSchema
>;

export type TCalculateChargesResponseSchema = ICharge;
