import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCalculateChargesRequestSchema,
  TCalculateChargesResponseSchema,
} from "./calculateCharges.schema";

export const calculateCharges = (axios: IAxiosInstance) => {
  return async (body: TCalculateChargesRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCalculateChargesResponseSchema>
    >(`/api/charges-and-fees/calculate`, body);

    return response.data;
  };
};
