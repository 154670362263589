import { schemes, zSchema } from "@zeal/common";

export const createPushToCardWorkerRequestSchema = zSchema.object({
  email: zSchema.string().email(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  odo_user_id: schemes.mongoOrUuid(),
  phone: zSchema.string(),
  address_1: zSchema.string(),
  address_2: zSchema.string().optional(),
  city: zSchema.string(),
  state: zSchema.string(),
  postal_code: zSchema.string(),
  date_of_birth: zSchema.string(),
  worker_id: zSchema.string(),
  ip_address: zSchema.string(),
});

export type TCreatePushToCardWorkerRequestSchema = zSchema.infer<
  typeof createPushToCardWorkerRequestSchema
>;

export const createPushToCardWorkerResponseSchema = zSchema.object({
  result: zSchema.object({
    id: schemes.uuid(),
    url: zSchema.string(),
    token: zSchema.string(),
    env: zSchema.string(),
    tenant: zSchema.string(),
  }),
  worker: zSchema.any(),
});

export type TCreatePushToCardWorkerResponseSchema = zSchema.infer<
  typeof createPushToCardWorkerResponseSchema
>;
