import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetChargeRequestSchema,
  TGetChargeResponseSchema,
} from "./getCharge.schema";

export const getCharge = (axios: IAxiosInstance) => {
  return async (params: TGetChargeRequestSchema) => {
    const { odo_user_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetChargeResponseSchema>
    >(`/api/charges-and-fees/${odo_user_id}/charges`, { params: rest });

    return response.data;
  };
};
