import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetWorkerAstraUserRequestSchema } from "./getWorkerAstraUser.schema";

export const getWorkerAstraUser = (axios: IAxiosInstance) => {
  return async (data: TGetWorkerAstraUserRequestSchema) => {
    const { worker_id } = data;
    const response = await axios.noRetry.post(`/push-to-card/worker`, {
      workerID: worker_id,
    });

    return response.data; // returns 204 no data
  };
};
